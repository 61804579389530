import React, { useEffect, useState, useRef } from 'react'
import {
  ChakraProvider,
  Box,
  extendTheme,
  Image,
  Center,
  Heading,
  Text,
  defineStyleConfig,
  useStyleConfig,
  Flex,
  Icon,
  Skeleton,
  SkeletonText,
  IconButton,
  HStack
} from '@chakra-ui/react'
import axios from 'axios'
import './style.css'
import CSSReset from './css-reset'
import logo192 from './images/logo192.png'

// Icons components
const PlayIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </svg>
);

const MusicNoteIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z" />
  </svg>
);

const PreviousIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
  </svg>
);

const NextIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
  </svg>
);

const VolumeIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
  </svg>
);

const MuteIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    {...props}
  >
    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
  </svg>
);

// Componente PlayerRadio
const PlayerRadio = (props) => {
  const { variant, ...rest } = props;
  const styles = useStyleConfig('PlayerTheme', { variant });
  return <Box __css={styles} {...rest} />;
};

// Componente principal App
const App = ({themeRadio, variant}) => {
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState("https://sonic.streamingchilenos.com/8184/stream");
  const [playlist, setPlaylist] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [cover, setCover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [volume, setVolume] = useState(1);
  const [youtubeVideo, setYoutubeVideo] = useState(null); // URL del video de YouTube
  const videoRef = useRef(null);
  
  // Define nuevo estilo para el reproductor
  const PlayerTheme = defineStyleConfig({
    baseStyle: {
      width: '100%',
      maxWidth: '480px',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'xl',
      backgroundColor: 'white',
      '.album-cover': {
        width: '100%',
        height: '320px',
        objectFit: 'cover',
        borderRadius: '0px'
      },
      '.play-button': {
        width: '64px',
        height: '64px',
        borderRadius: 'full',
        bg: 'white',
        color: 'brand.900',
        boxShadow: 'lg',
        _hover: {
          transform: 'scale(1.1)'
        },
        transition: 'all 0.3s'
      },
      '.on-air-badge': {
        position: 'absolute',
        top: '12px',
        left: '12px',
        px: '2',
        py: '1',
        bg: 'red.500',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 'xs',
        borderRadius: 'md',
        zIndex: 10
      },
      '.control-button': {
        bg: 'transparent',
        color: 'gray.600',
        _hover: {
          color: 'brand.900',
          bg: 'gray.100'
        }
      },
      '.timeline': {
        height: '4px',
        bg: 'gray.200',
        width: '100%',
        '.progress': {
          height: '100%',
          bg: 'brand.900',
          borderRadius: 'full'
        }
      },
      '.playlist-item': {
        fontSize: 'sm',
        color: 'gray.700',
        py: '2',
        borderBottom: '1px solid',
        borderColor: 'gray.100',
        _hover: {
          bg: 'gray.50'
        }
      }
    }
  });

  // Nuevo theme moderno
  const theme = extendTheme({
    colors: {
      brand: {
        100: themeRadio ? themeRadio[0] : "#FFFFFF", // Fondo (Blanco)
        900: themeRadio ? themeRadio[1] : "#0091DA", // Acento (Azul)
      },
    },
    components: {
      PlayerTheme,
    },
    styles: {
      global: {
        body: {
          fontFamily: "'Inter', sans-serif",
          lineHeight: null,
          backgroundColor: 'transparent',
          color: null,
        }
      }
    }
  });
  
  // Función para extraer el ID de video de YouTube de una URL
  const getYouTubeVideoId = (youtubeUrl) => {
    if (!youtubeUrl) return null;
    
    // Patrones comunes de URL de YouTube
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/i,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^/?]+)/i,
      /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^/?]+)/i,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([^/?]+)/i
    ];
    
    for (const pattern of patterns) {
      const match = youtubeUrl.match(pattern);
      if (match) return match[1];
    }
    
    return null;
  };
  
  // Función para crear una URL de embed de YouTube
  const getYouTubeEmbedUrl = (youtubeUrl) => {
    const videoId = getYouTubeVideoId(youtubeUrl);
    if (!videoId) return null;
    
    // Parametros para autoplay y control de volumen
    const params = new URLSearchParams({
      autoplay: 1,
      mute: 0,
      controls: 1,
      rel: 0
    });
    
    return `https://www.youtube.com/embed/${videoId}?${params.toString()}`;
  };
  
  // Comprobar si tenemos una URL de video estática o video de YouTube
  useEffect(() => {
    // Verificar si hay una URL de video estática
    if (window.staticVideoUrl) {
      setUrl(window.staticVideoUrl);
    }
    
    // Simular recepción de URL de YouTube (esto se reemplazaría con tu implementación real)
    // Por ejemplo, podrías tener un evento que escuche cambios en una base de datos o API
    const checkForYouTubeVideo = () => {
      // Esta es la simulación - en la implementación real, esto vendría de una fuente externa
      const simulatedYouTubeUrl = window.youtubeVideoUrl || null;
      
      if (simulatedYouTubeUrl) {
        setYoutubeVideo(simulatedYouTubeUrl);
        // Si hay un video de YouTube, pausamos el stream de audio
        if (playing) {
          // Pausamos pero no cambiamos el estado para poder reanudar cuando el video termine
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }
      } else {
        setYoutubeVideo(null);
      }
    };
    
    // Verificamos inicialmente
    checkForYouTubeVideo();
    
    // Configuramos un intervalo para comprobar periódicamente
    const youtubeCheckInterval = setInterval(checkForYouTubeVideo, 30000);
    
    return () => {
      clearInterval(youtubeCheckInterval);
    };
  }, []);

  // Control de volumen
  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const toggleMute = () => {
    if (volume > 0) {
      handleVolumeChange(0);
    } else {
      handleVolumeChange(1);
    }
  };

  const play = () => {
    // Si hay un video de YouTube activo, no hacemos nada
    if (youtubeVideo) {
      return;
    }
    
    if (!playing) {
      // Si va a comenzar a reproducir
      setPlaying(true);
    } else {
      // Si va a detener la reproducción
      setPlaying(false);
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = "";
      }
    }
  };

  const getPlaylist = () => {
    setLoading(true);
    axios.get('https://clownfish-app-r9uob.ondigitalocean.app/stream-history')
    .then(async res => {
      const playlist = formatPlayList(res.data.songs);
      setPlaylist(playlist);

      // Última cancion sonando
      if (playlist && playlist.length > 0) {
        const [title, artist] = playlist[0].title.trim().split(' - ');
        setCurrentSong({
          title: title,
          artist: artist || "Unknown Artist"
        });

        // Guardamos la URL de la carátula anterior para comparar
        const oldCover = cover;
        const coverImage = await getCover(playlist[0].title);
        
        // Solo actualizamos la carátula si es diferente para evitar el salto visual
        if (coverImage !== oldCover) {
          setCover(coverImage);
        }
      }
      setLoading(false);
    })
    .catch(err => {
      console.error("Error obteniendo la playlist:", err);
      setLoading(false);
    });
  };

  const getCover = async (title) => {
    try {
      const res = await axios.get('https://itunes.apple.com/search?term=' + encodeURIComponent(title));
      let { data } = res;
      const item = data.results[0];

      if (!item || !item.artworkUrl100) {
        return false;
      }

      const url = item.artworkUrl100.replace(/100x100/, '500x500');
      return url;
    } catch (error) {
      console.error("Error fetching cover:", error);
      return false;
    }
  };

  const formatPlayList = (playlist) => {
    const historySongs = playlist.map(function(element) {
      return {
        title: element.title ? element.title.trim() : ""
      };
    });
    
    //Filtro para los avisos
    const historySongsFiltered = historySongs.filter(function(item) {
      if (!item.title || 
          item.title === 'unknown artist' || 
          item.title === 'Ads' || 
          item.title === 'RADIOSINERGIA' || 
          item.title === 'Empty Title' || 
          item.title === 'Rock Pop Music' || 
          /^\d/.test(item.title))
        return false;
      
      return true;
    });
    
    // Eliminar elementos repetidos
    const uniqueSongs = historySongsFiltered.reduce((acc, current) => {
      const x = acc.find(item => item.title === current.title);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    
    return uniqueSongs;
  };

  useEffect(() => {
    getPlaylist();
    const interval = setInterval(() => {
      getPlaylist();
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  
  // Efecto para manejar el volumen cuando cambia videoRef
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [videoRef, volume]);
  
  // Efecto para simular la recepción de video de YouTube
  // (solo para demostración - esto se eliminaría en la implementación real)
  useEffect(() => {
    const simulateYouTubeVideoReceived = () => {
      // Para probar, puedes cambiar esta URL por una URL de YouTube válida
      // window.youtubeVideoUrl = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      // Para simular que se quita el video, descomentar la siguiente línea:
      window.youtubeVideoUrl = null;
      
      // Forzar una comprobación
      const event = new Event('checkYouTubeVideo');
      window.dispatchEvent(event);
    };
    
    // Escuchar evento personalizado para comprobar YouTube
    const handleCheckYouTube = () => {
      const url = window.youtubeVideoUrl || null;
      setYoutubeVideo(url);
    };
    
    window.addEventListener('checkYouTubeVideo', handleCheckYouTube);
    
    // Simular recepción después de 3 segundos (solo para demostración)
    const timer = setTimeout(simulateYouTubeVideoReceived, 3000);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('checkYouTubeVideo', handleCheckYouTube);
    };
  }, []);
  
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <CSSReset />
      <Center minH="100vh" flexDirection="column" p={4}>
        <PlayerRadio className="chakra-reset">
          {/* Sección de "ON AIR" */}
          <Box className="on-air-badge">ON AIR</Box>
          
          {/* Sección de la carátula o video de YouTube */}
          <Box position="relative" width="100%" height="320px">
            {youtubeVideo ? (
              // Si hay un video de YouTube, lo mostramos en un iframe
              <Box width="100%" height="100%">
                <iframe
                  src={getYouTubeEmbedUrl(youtubeVideo)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  width="100%"
                  height="100%"
                ></iframe>
              </Box>
            ) : loading ? (
              <Skeleton height="100%" width="100%" />
            ) : (
              <Image 
                src={cover || logo192} 
                alt={currentSong ? currentSong.title : "Album Cover"} 
                className="album-cover"
                fallbackSrc={logo192}
                width="100%"
                height="100%"
                objectFit="cover"
              />
            )}
            
            {/* Botón de Play/Pause sobre la carátula (solo si no hay video) */}
            {!youtubeVideo && (
              <Center
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
              >
                <IconButton
                  aria-label={playing ? "Pause" : "Play"}
                  icon={playing ? <Icon as={PauseIcon} w={8} h={8} /> : <Icon as={PlayIcon} w={8} h={8} />}
                  onClick={play}
                  className="play-button"
                />
              </Center>
            )}
          </Box>
          
          {/* Información de la canción o video */}
          <Box p={4} bg="white">
            {loading ? (
              <Box>
                <Skeleton height="24px" width="80%" mb={2} />
                <Skeleton height="16px" width="60%" />
              </Box>
            ) : youtubeVideo ? (
              <Box>
                <Heading 
                  color="gray.800" 
                  fontSize="xl" 
                  fontWeight="bold" 
                  mb={1}
                  isTruncated
                >
                  Transmisión en vivo
                </Heading>
                <Text
                  color="gray.600"
                  fontSize="md"
                  isTruncated
                >
                  YouTube
                </Text>
                <Text
                  color="blue.500"
                  fontSize="sm"
                  mt={1}
                >
                  Radio Sinergia 104.5FM
                </Text>
              </Box>
            ) : currentSong ? (
              <Box>
                <Heading 
                  color="gray.800" 
                  fontSize="xl" 
                  fontWeight="bold" 
                  mb={1}
                  isTruncated
                >
                  {currentSong.title}
                </Heading>
                <Text
                  color="gray.600"
                  fontSize="md"
                  isTruncated
                >
                  {currentSong.artist}
                </Text>
                <Text
                  color="blue.500"
                  fontSize="sm"
                  mt={1}
                >
                  Radio Sinergia 104.5FM
                </Text>
              </Box>
            ) : (
              <Box>
                <Heading 
                  color="gray.800" 
                  fontSize="xl" 
                  fontWeight="bold" 
                  mb={1}
                >
                  Sin reproducción
                </Heading>
                <Text
                  color="gray.600"
                  fontSize="md"
                >
                  Radio Sinergia 104.5FM
                </Text>
              </Box>
            )}
          </Box>
          
          {/* Barra de tiempo (simulada) */}
          <Box className="timeline" mx={4}>
            <Box 
              className="progress" 
              width={youtubeVideo ? "100%" : "35%"}
              transition="width 0.5s ease-in-out"
            ></Box>
          </Box>
          
          {/* Controles de reproducción */}
          <Flex justify="space-between" align="center" p={4}>
            <HStack spacing={4} flex={1} justifyContent="center">
              <IconButton
                aria-label="Previous"
                icon={<Icon as={PreviousIcon} w={6} h={6} />}
                className="control-button"
                isDisabled={true}
                opacity={0.5}
              />
              <IconButton
                aria-label={playing && !youtubeVideo ? "Pause" : "Play"}
                icon={playing && !youtubeVideo ? <Icon as={PauseIcon} w={6} h={6} /> : <Icon as={PlayIcon} w={6} h={6} />}
                onClick={play}
                className="control-button"
                size="lg"
                isDisabled={youtubeVideo}
                opacity={youtubeVideo ? 0.5 : 1}
              />
              <IconButton
                aria-label="Next"
                icon={<Icon as={NextIcon} w={6} h={6} />}
                className="control-button"
                isDisabled={true}
                opacity={0.5}
              />
            </HStack>
            
            <Flex alignItems="center">
              <IconButton
                aria-label={volume === 0 ? "Unmute" : "Mute"}
                icon={<Icon as={volume === 0 ? MuteIcon : VolumeIcon} w={6} h={6} />}
                className="control-button"
                onClick={toggleMute}
                isDisabled={youtubeVideo}
                opacity={youtubeVideo ? 0.5 : 1}
              />
              
              {/* Control de volumen */}
              <Box width="80px" ml={2}>
                <input 
                  type="range" 
                  min="0" 
                  max="1" 
                  step="0.01" 
                  value={volume}
                  onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                  style={{
                    width: '100%',
                    accentColor: theme.colors.brand[900],
                    opacity: youtubeVideo ? 0.5 : 1
                  }}
                  disabled={youtubeVideo}
                />
              </Box>
            </Flex>
          </Flex>
          
          {/* Sección de historial */}
          <Box p={2} bg="gray.50">
            <Heading 
              fontSize="md" 
              fontWeight="semibold" 
              mb={2} 
              px={2}
              color="gray.700"
            >
              {youtubeVideo ? "TRANSMISIÓN EN VIVO" : "UP NEXT"}
            </Heading>
            
            {youtubeVideo ? (
              <Center p={4}>
                <Text color="gray.500">
                  Reproduciendo transmisión de YouTube. El historial de canciones se reanudará cuando termine.
                </Text>
              </Center>
            ) : loading ? (
              <SkeletonText mt="1" noOfLines={3} spacing="4" px={2} />
            ) : playlist && playlist.length > 0 ? (
              <Box maxH="200px" overflowY="auto">
                {playlist.slice(0, 5).map((item, index) => (
                  <Flex 
                    key={index} 
                    className="playlist-item" 
                    px={2}
                    align="center"
                  >
                    <Icon as={MusicNoteIcon} w={4} h={4} mr={2} color="gray.400" />
                    <Text isTruncated>
                      {item.title}
                    </Text>
                  </Flex>
                ))}
              </Box>
            ) : (
              <Center p={4}>
                <Text color="gray.500">No hay historial disponible</Text>
              </Center>
            )}
          </Box>

          {/* Video element para streaming (solo si no hay YouTube y está reproduciendo) */}
          {playing && !youtubeVideo && (
            <video 
              ref={videoRef}
              src={url} 
              preload="auto" 
              autoPlay 
              style={{display: 'none'}}
              onPlay={() => console.log("Stream iniciado correctamente")}
              onError={(e) => {
                console.error("Error reproduciendo el stream:", e);
                setPlaying(false);
                alert("Error al cargar el stream. Por favor, intente nuevamente.");
              }}
            />
          )}
        </PlayerRadio>
      </Center>
    </ChakraProvider>
  );
};

export default App;