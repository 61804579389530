import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';

// Definir el shader material para el agua
const WaterMaterial = shaderMaterial(
  { time: 0 },
  // Vertex Shader
  `
    varying vec2 vUv;
    uniform float time;

    // Función de ruido simple
    float noise(vec3 p) {
      return sin(p.x * 10.0 + time) * 0.005 + sin(p.y * 10.0 + time) * 0.005 + sin(p.z * 10.0 + time) * 0.005;
    }

    // Función de ruido mejorada para deformaciones
    float improvedNoise(vec3 p) {
      return noise(p) + noise(p * 2.0) * 0.025 + noise(p * 4.0) * 0.0125;
    }

    // Función para combinar múltiples ondas suaves
    float smoothWaves(vec3 p) {
      float wave1 = sin(p.x * 5.0 + time * 0.5) * 0.05;
      float wave2 = sin(p.y * 7.0 + time * 0.3) * 0.03;
      float wave3 = sin(p.z * 9.0 + time * 0.2) * 0.02;
      return wave1 + wave2 + wave3;
    }

    void main() {
      vUv = uv;
      vec3 newPosition = position + normal * smoothWaves(position + vec3(time * 0.1));
      gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
    }
  `,
  // Fragment Shader
  `
    uniform float time;
    varying vec2 vUv;

    // Función de ruido para crear formas líquidas
    float noise(vec2 p) {
      return sin(p.x * 10.0 + time) * 0.5 + sin(p.y * 10.0 + time) * 0.5;
    }

    float fbm(vec2 p) {
      float value = 0.0;
      float amplitude = 0.5;
      for (int i = 0; i < 5; i++) {
        value += amplitude * noise(p);
        p *= 2.0;
        amplitude *= 0.5;
      }
      return value;
    }

    void main() {
      vec2 uv = vUv;
      vec3 color = vec3(0.0);
      float strength = 0.5;

      // Crear un efecto de agua con formas líquidas
      float liquidPattern = fbm(uv * 3.0 + time * 0.1);
      color = mix(vec3(0.0, 0.61, 0.86), vec3(0.0, 0.0, 1.0), liquidPattern); // Base de color azul #009bdd

      gl_FragColor = vec4(color, 1.0);
    }
  `
);

extend({ WaterMaterial });

const WaterPlane = () => {
  const meshRef = useRef();
  const materialRef = useRef();

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.x = -Math.PI / 2; // Rotar el plano para que sea horizontal
    }
    if (materialRef.current) {
      materialRef.current.uniforms.time.value = state.clock.getElapsedTime();
    }
  });

  return (
    <mesh ref={meshRef}>
      <planeGeometry args={[10, 10, 64, 64]} />
      <waterMaterial ref={materialRef} />
      <ambientLight intensity={0.3} color={new THREE.Color(0x404040)} />
      <pointLight position={[10, 10, 10]} intensity={0.8} color={new THREE.Color(0xadd8e6)} />
    </mesh>
  );
};

const Smoke = () => {
  const groupRef = useRef();
  const particles = [];

  for (let i = 0; i < 100; i++) {
    const particle = {
      position: new THREE.Vector3(
        (Math.random() - 0.5) * 20,
        (Math.random() - 0.5) * 20,
        (Math.random() - 0.5) * 20
      ),
      rotation: new THREE.Euler(
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI
      )
    };
    particles.push(particle);
  }

  useFrame((state) => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.001; // Rotar lentamente el grupo de humo
    }
    particles.forEach((particle, index) => {
      const mesh = groupRef.current.children[index];
      if (mesh) {
        mesh.position.y += Math.sin(state.clock.getElapsedTime() + index) * 0.01; // Animar la posición en Y
        mesh.rotation.z += 0.01; // Rotar lentamente cada plano
      }
    });
  });

  return (
    <group ref={groupRef}>
      {particles.map((particle, index) => (
        <mesh key={index} position={particle.position} rotation={particle.rotation}>
          <planeGeometry args={[0.2, 0.2]} /> {/* Planos más pequeños */}
          <meshBasicMaterial
            color={0xffffff}
            transparent
            opacity={0.8} // Mayor opacidad para simular estrellas
            depthWrite={false}
          />
        </mesh>
      ))}
    </group>
  );
};

const Water = () => {
  return (
    <Canvas>
      <Smoke />
      <WaterPlane />
    </Canvas>
  );
};

export default Water;