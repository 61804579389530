import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const renderizarAplicacion = (elementos) => {
  elementos.forEach((elemento) => {
    if(elemento) {
      const root = ReactDOM.createRoot(elemento);
      root.render(
        <StrictMode>
        <ColorModeScript />
        <App themeRadio={ window.themeRadio } />
        </StrictMode>
      );
    }
  });
}

const clases = ['wp-react-app'];
const elementosDOM = [];

clases.forEach((clase) => {
  const elementos = document.getElementsByClassName(clase);
  elementosDOM.push(...elementos);
});
renderizarAplicacion(elementosDOM);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
