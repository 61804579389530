import { createIcon } from '@chakra-ui/icons'

// using `path`
export const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 270 270',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g fill="currentColor"><path d="M135.977 271.953c75.097 0 135.977-60.879 135.977-135.977S211.074 0 135.977 0 0 60.879 0 135.977s60.879 135.976 135.977 135.976zm114.22-135.976c0 62.979-51.241 114.22-114.22 114.22s-114.22-51.241-114.22-114.22 51.241-114.22 114.22-114.22 114.22 51.241 114.22 114.22z"></path><path d="M112.295 205.031c2.692 1.115 5.434 1.659 8.235 1.659a21.63 21.63 0 0015.344-6.375l48.93-48.952c8.496-8.496 8.485-22.273-.011-30.769l-48.957-48.952a21.765 21.765 0 00-15.393-6.375c-2.801 0-5.461.544-8.153 1.659-8.126 3.367-13.255 11.297-13.255 20.097v97.903c-.001 8.803 5.129 16.738 13.26 20.105zm8.496-116.418v-1.588l48.952 48.952-48.952 48.952V88.613z"></path></g>
  ),
})

export const PauseIcon = createIcon({
  displayName: 'PauseIcon',
  viewBox: '0 0 270 270',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g fill="currentColor"><path d="M135.977 271.953c75.097 0 135.977-60.879 135.977-135.977S211.074 0 135.977 0 0 60.879 0 135.977s60.879 135.976 135.977 135.976zm0-250.197c62.979 0 114.22 51.241 114.22 114.22s-51.241 114.22-114.22 114.22-114.22-51.241-114.22-114.22 51.235-114.22 114.22-114.22z"></path><path d="M110.707 200.114c7.511 0 13.598-6.086 13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598-7.511 0-13.598 6.086-13.598 13.598v103.342c0 7.512 6.086 13.598 13.598 13.598zM165.097 200.114c7.511 0 13.598-6.086 13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598S151.5 75.663 151.5 83.174v103.342c0 7.512 6.086 13.598 13.597 13.598z"></path></g>
  ),
})


