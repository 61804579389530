import React, { useEffect, useState } from 'react'
import {
  ChakraProvider,
  Box,
  Grid,
  extendTheme,
  Image,
  Center,
  Heading,
  Text,
  defineStyleConfig,
  useStyleConfig
} from '@chakra-ui/react'
import { PlayIcon, PauseIcon } from './Icons'
import axios from 'axios'
import './style.css'
import CSSReset from './css-reset'
import Sphere from './Sphere'
import radiosinergia001 from './images/radiosinergia001.jpg'
import radiosinergia002 from './images/radiosinergia002.jpg'
import radiosinergia003 from './images/radiosinergia003.jpg'
import radiosinergia004 from './images/radiosinergia004.jpg'
import logo192 from './images/logo192.png'

function App({themeRadio, variant}) {
  const [playing, setPlaying ] = useState(false);
  const [url] = useState("https://sonic.streamingchilenos.com/8184/stream");
  const [playlist, setPlaylist] = useState(false);
  const [currentSong, setCurrentSong] = useState(false);
  const [cover, setCover] = useState(false);
  
  // Array de imágenes disponibles
  const images = [
    radiosinergia001,
    radiosinergia002,
    radiosinergia003,
    radiosinergia004
  ];
  
  // Seleccionar una imagen aleatoria al cargar
  const [randomImage] = useState(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  });
  const PlayerTheme = defineStyleConfig({
    baseStyle: {
      '.current-image': {
        borderRadius: 'full',
        boxSize: '80px'
      },
      '.current-song': {
        textAlign: 'left',
        fontSize: 'md',
        color: 'whiteAlpha.600',
      },
      '.current-artist': {
        textAlign: 'left',
        fontSize: 'xl'
      },
      '.playlist-item': {
        fontSize: 'md',
        color: 'brand.900',
        textAlign: 'left',
      },
    }
  })

  const theme = extendTheme({
    colors: {
      brand: {
        100: themeRadio[0],
        900: themeRadio[1],
      },
    },
    components: {
      PlayerTheme,
    },
    styles: {
      global: {
        body: {
          fontFamily: null,
          lineHeight: null,
          backgroundColor: 'transparent',
          color: null,
        }
      }
    }
  })  

  const play = () => {
    setPlaying(!playing)
  }

  const getPlaylist = () => {
    axios.get('https://clownfish-app-r9uob.ondigitalocean.app/stream-history')
    .then( async res => {
      const playlist = formatPlayList(res.data.songs)
      setPlaylist(playlist)

      // Última cancion sonando
      const [title, artist] = playlist[0].title.trim().split(' - ');
      setCurrentSong({
        title: title,
        artist: artist
      })

      const cover = await getCover(playlist[0].title)
      setCover(cover)
    })
    .catch(err => {
      console.log(err)
    })
  }

  const getCover = async (title) => {
    const res = await axios.get('https://itunes.apple.com/search?term=' + title)
    let { data } = res;
    const item = data.results[0];

    if (!item || !item.artworkUrl100) {
      return false;
    }

    const url = item.artworkUrl100.replace(/100x100/, '500x500')
    return url
  }

  const formatPlayList = (playlist) => {
    const historySongs = playlist.map(function(element) {
      return {
        title: element.title.trim()
      };
    })
    
    //Filtro para los avisos
    const historySongsFiltered = historySongs.filter(function(item) {
      if (item.title === 'unknown artist' || item.title === 'Ads' || item.title === undefined || item.title === 'RADIOSINERGIA' || item.title === 'Empty Title' || item.title === 'Rock Pop Music' || /^\d/.test(item.title))
        return false;
      
      return true;
    });
    
    // Eliminar elementos repetidos
    const uniqueSongs = historySongsFiltered.reduce((acc, current) => {
      const x = acc.find(item => item.title === current.title);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    
    return uniqueSongs;
  }

  useEffect(() => {
    getPlaylist()
    const interval = setInterval(() => {
      getPlaylist()
    }, 10000);
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, []) 
  
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <CSSReset />
      <Center minH="100vh" flexDirection="column">
        <PlayerRadio className="chakra-reset">
          <Grid display={{ md: 'flex' }}>
            <Box bg={'brand.100'} p='2' width={['100%', '100%', '120px', '120px']} display={'flex'} alignItems={'center'} justifyContent={'center'} >
              <Center><a href={"https://www.youtube.com/results?search_query=" + currentSong.title} target='_blank' rel='noreferrer'>
                <Image src={cover || logo192} alt={currentSong.title} className='current-image' />
              </a></Center>
            </Box>

            <Box bg={'brand.100'} width={['100%', '100%', '30%', '30%']} p="2" display={'flex'} alignItems={'center'} justifyContent={ { base: 'center', md: 'left' } }>
              <div className='current-playing'>
                {currentSong && <>
                  <Heading mb={4} color='brand.900' className='current-artist' title={currentSong.title}>{currentSong.title}</Heading>
                  <Text color='brand.900' className='current-song'title={currentSong.title}>{currentSong.artist}</Text>
                </>
                }
              </div>
            </Box>

            <Box bg={'brand.900'} color={'brand.100'} width={[ '100%', '100%','20%', '20%']} py="3" display={'flex'} justifyContent={'center'}>
              <Center>
              <button onClick={() => play() } className='play-button'>
                  {playing ? <PauseIcon w={55} h={55} /> : <><PlayIcon w={55} h={55} /></> }
              </button>
              </Center>
            </Box>

            <Box
              width={[ '100%', '100%', '50%', '50%']}
              height={ { base: '120px', md: '100px' }}
              bg={'brand.100'} color={'brand.900'} 
              >
              <Box px='2.5' mt={{ base: '1rem', md: 0 }} textAlign={'left'}className='scrollbar'height='100px'>
                  {playlist && playlist.map((item, index) => (
                    <Text key={index} mb={'1.5'} className='playlist-item'> <Text as='b' fontSize={'xs'} mr={'2'}>♬</Text> 
                    <a href={"https://www.youtube.com/results?search_query=" + item.title} target='_blank' rel='noreferrer'>{item.title}</a>
                    </Text>
                  ))}
              </Box>
            </Box>
          </Grid>

          {playing && <video src={url} preload="auto" autoPlay style={{display: 'none'}}></video>}
        </PlayerRadio>

        {/* Imagen aleatoria que cambia al recargar */}
        <Box mt={8} width={["90%", "80%", "70%", "60%"]} mx="auto">
          <Image 
            src={randomImage}
            alt="Imagen aleatoria"
            borderRadius="lg"
            shadow="lg"
            width="100%"
            height="auto"
            objectFit="cover"
          />
        </Box>
      </Center>
    </ChakraProvider>
  );
}

export default App;

function PlayerRadio(props) {
  const { variant, ...rest } = props
  const styles = useStyleConfig('PlayerTheme', { variant })
  return <Box __css={styles} {...rest} />
}