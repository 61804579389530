import { Global } from "@emotion/react"

const vhPolyfill = `
  :root {
    --chakra-vh: 100vh;
  }

  @supports (height: -webkit-fill-available) {
    :root {
      --chakra-vh: -webkit-fill-available;
    }
  }

  @supports (height: -moz-fill-available) {
    :root {
      --chakra-vh: -moz-fill-available;
    }
  }

  @supports (height: 100dvh) {
    :root {
      --chakra-vh: 100dvh;
    }
  }
`

export const CSSPolyfill = () => <Global styles={vhPolyfill} />

export const CSSReset = () => (
  <Global
    styles={`
      html .chakra-reset {
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        font-family: system-ui, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        touch-action: manipulation;
      }

      body .chakra-reset {
        position: relative;
        min-height: 100%;
        font-feature-settings: 'kern';
      }

      .chakra-reset *,
      .chakra-reset *::before,
      .chakra-reset *::after {
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
      }

      .chakra-reset main {
        display: block;
      }

      .chakra-reset hr {
        border-top-width: 1px;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
      }

      .chakra-reset pre,
      .chakra-reset code,
      .chakra-reset kbd,
      .chakra-reset samp {
        font-family: SFMono-Regular,  Menlo, Monaco, Consolas, monospace;
        font-size: 1em;
      }

      .chakra-reset a {
        background-color: transparent;
        color: inherit;
        text-decoration: inherit;
      }

      .chakra-reset abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
      }

      .chakra-reset b,
      strong {
        font-weight: bold;
      }

      .chakra-reset small {
        font-size: 80%;
      }

      .chakra-reset sub,
      .chakra-reset sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }

      .chakra-reset sub {
        bottom: -0.25em;
      }

      .chakra-reset sup {
        top: -0.5em;
      }

      .chakra-reset img {
        border-style: none;
      }

      .chakra-reset button,
      .chakra-reset input,
      .chakra-reset optgroup,
      .chakra-reset select,
      .chakra-reset textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
      }

      .chakra-reset button,
      .chakra-reset input {
        overflow: visible;
      }

      .chakra-reset button,
      .chakra-reset select {
        text-transform: none;
      }

      .chakra-reset button::-moz-focus-inner,
      .chakra-reset [type="button"]::-moz-focus-inner,
      .chakra-reset [type="reset"]::-moz-focus-inner,
      .chakra-reset [type="submit"]::-moz-focus-inner {
        border-style: none;
        padding: 0;
      }

      .chakra-reset fieldset {
        padding: 0.35em 0.75em 0.625em;
      }

      .chakra-reset legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
      }

      .chakra-reset progress {
        vertical-align: baseline;
      }

      .chakra-reset textarea {
        overflow: auto;
      }

      .chakra-reset [type="checkbox"],
      .chakra-reset [type="radio"] {
        box-sizing: border-box;
        padding: 0;
      }

      .chakra-reset [type="number"]::-webkit-inner-spin-button,
      .chakra-reset [type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      .chakra-reset input[type="number"] {
        -moz-appearance: textfield;
      }

      .chakra-reset [type="search"] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }

      .chakra-reset [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none !important;
      }

      .chakra-reset ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }

      .chakra-reset details {
        display: block;
      }

      .chakra-reset summary {
        display: list-item;
      }

      .chakra-reset template {
        display: none;
      }

      .chakra-reset [hidden] {
        display: none !important;
      }

      .chakra-reset body,
      .chakra-reset blockquote,
      .chakra-reset dl,
      .chakra-reset dd,
      .chakra-reset h1,
      .chakra-reset h2,
      .chakra-reset h3,
      .chakra-reset h4,
      .chakra-reset h5,
      .chakra-reset h6,
      .chakra-reset hr,
      .chakra-reset figure,
      .chakra-reset p,
      .chakra-reset pre {
        margin: 0;
      }

      .chakra-reset button {
        background: transparent;
        padding: 0;
      }

      .chakra-reset fieldset {
        margin: 0;
        padding: 0;
      }

      .chakra-reset ol,
      .chakra-reset ul {
        margin: 0;
        padding: 0;
      }

      .chakra-reset textarea {
        resize: vertical;
      }

      .chakra-reset button,
      [role="button"] {
        cursor: pointer;
      }

      .chakra-reset button::-moz-focus-inner {
        border: 0 !important;
      }

      .chakra-reset table {
        border-collapse: collapse;
      }

      .chakra-reset h1,
      .chakra-reset h2,
      .chakra-reset h3,
      .chakra-reset h4,
      .chakra-reset h5,
      .chakra-reset h6 {
        font-size: inherit;
        font-weight: inherit;
      }

      .chakra-reset button,
      .chakra-reset input,
      .chakra-reset optgroup,
      .chakra-reset select,
      .chakra-reset textarea {
        padding: 0;
        line-height: inherit;
        color: inherit;
      }

      .chakra-reset img,
      .chakra-reset svg,
      .chakra-reset video,
      .chakra-reset canvas,
      .chakra-reset audio,
      .chakra-reset iframe,
      .chakra-reset embed,
      .chakra-reset object {
        display: block;
      }

      .chakra-reset img,
      .chakra-reset video {
        max-width: 100%;
        height: auto;
      }

      .chakra-reset [data-js-focus-visible] :focus:not([data-focus-visible-added]):not([data-focus-visible-disabled]) {
        outline: none;
        box-shadow: none;
      }

      .chakra-reset select::-ms-expand {
        display: none;
      }

      ${vhPolyfill}
    `}
  />
)

export default CSSReset